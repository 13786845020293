import * as React from "react";
import { useState } from "react";
import S from "./hero.module.sass";
import SVGLake from "./svg-lake";
import SVGLogo from "./svg-logo";


const Hero = () => {
  const [showLake, setShowLake] = useState(false);

  return (
    <div className={S.logo}>
      {showLake && (
        <div id="lake">
          <SVGLake setShowLake={setShowLake} />
        </div>
      )}
      <SVGLogo setShowLake={setShowLake} />
    </div>
  );
};

export default Hero;
