import * as React from "react";
import { useEffect } from "react";
import S from "./svg-logo.module.sass";
import gsap from "gsap";

type Props = {
  setShowLake: (value: boolean) => void;
}

const SVGLogo = ({ setShowLake }: Props) => {

  // Mouse Wheel Icon animation
  useEffect(() => {
    const tl = gsap.timeline();
    tl.to("#logo", {
      fillOpacity: 1,
      duration: 2,
      delay: 1,
      onComplete: () => {
        // console.log('logo animation has been done, start to display lake');
        setShowLake(true);
      }
    });
  }, []);

  return (
    <div className={S.svg}>
      <div className={S.logo}>
        <svg viewBox="0 0 293.093 108.907">
          <g id="logo" fillOpacity={0}>
            <g className={S.text}>
              <polygon
                points="265.216,56.512 265.216,0.003 256.174,0.003 256.174,60.279 291.587,60.282 293.093,56.514 		" />
              <polygon points="244.119,3.77 245.626,0.003 205.714,0 205.714,0.003 205.693,0.003 205.693,60.279 244.119,60.279
			245.626,56.512 214.734,56.512 214.734,27.504 241.105,27.504 242.612,23.736 214.734,23.736 214.734,3.768 		" />
              <path d="M129.971,36.168c0,13.185-10.172,19.967-19.59,19.967h0c-13.185,0-19.59-9.418-19.59-19.967V0.003H81.75v33.905
			c0,16.576,10.498,26.748,26.748,26.748h0c12.432,0,25.241-6.028,25.241-25.994V0.002l-3.767,0.001V36.168z" />
              <path d="M171.496,45.963l10.086,14.316h11.302l-12.387-16.516c8.328-3.543,14.27-11.107,14.27-20.78
			c0-13.562-11.679-22.98-25.241-22.98l-15.823,0v0h-9.041v60.288l9.041-0.012V45.963L171.496,45.963z M168.02,42.196h-14.316V3.77
			h14.316c10.548,0,17.329,7.158,17.329,19.213S178.569,42.196,168.02,42.196z" />
              <polygon points="54.249,47.47 52.742,43.703 33.444,43.703 47.792,6.674 68.564,60.279 78.359,60.279 55.002,0.003 46.337,0.003
			27.944,47.47 		" />
              <path d="M0,61.002c15.377-4.014,22.975-2.22,30.342-0.515c5.907,1.367,12.051,3.052,22.776,0.168l-1.409-3.523
			c-9.036,2.368-14.37,1.123-20.509-0.313c-5.755-1.346-12.075-2.823-22.159-1.472V0.003H0V61.002z" />
            </g>
            <g className={S.small}>
              <path d="M56.676,108.015c-0.971-0.595-1.717-1.398-2.239-2.411c-0.522-1.012-0.783-2.114-0.783-3.303
			c0-1.357,0.344-2.51,1.033-3.46c0.689-0.95,1.565-1.659,2.63-2.129c1.064-0.47,2.16-0.704,3.288-0.704
			c1.294,0,2.421,0.303,3.382,0.908c0.96,0.606,1.696,1.404,2.207,2.395c0.511,0.992,0.767,2.051,0.767,3.178
			c0,1.252-0.313,2.364-0.939,3.334c-0.626,0.971-1.467,1.727-2.52,2.27c-1.054,0.543-2.197,0.814-3.428,0.814
			C58.778,108.907,57.646,108.61,56.676,108.015z M62.875,107.044c0.428-0.803,0.642-1.957,0.642-3.46
			c0-1.461-0.188-2.713-0.563-3.757c-0.376-1.044-0.866-1.832-1.472-2.364c-0.606-0.532-1.252-0.798-1.941-0.798
			c-1.67,0-2.505,1.493-2.505,4.477c0,1.399,0.177,2.635,0.532,3.71c0.355,1.075,0.835,1.91,1.44,2.505
			c0.605,0.595,1.273,0.892,2.004,0.892C61.826,108.25,62.447,107.848,62.875,107.044z" />
              <path d="M87.535,108.312c0,0.125-0.031,0.188-0.094,0.188c-0.543,0-0.971-0.01-1.284-0.031l-1.879-0.031l-1.816,0.031
			c-0.334,0.021-0.773,0.031-1.315,0.031c-0.063,0-0.094-0.063-0.094-0.188s0.031-0.188,0.094-0.188
			c0.647,0,1.085-0.151,1.315-0.454c0.229-0.302,0.344-0.871,0.344-1.706v-4.509c0-1.127-0.204-1.962-0.611-2.505
			c-0.407-0.543-1.049-0.814-1.925-0.814c-0.501,0-1.018,0.105-1.55,0.313c-0.532,0.209-0.997,0.491-1.393,0.845v6.669
			c0,0.835,0.12,1.404,0.36,1.706c0.24,0.303,0.683,0.454,1.331,0.454c0.063,0,0.094,0.063,0.094,0.188s-0.031,0.188-0.094,0.188
			c-0.543,0-0.971-0.01-1.284-0.031l-1.879-0.031l-1.847,0.031c-0.313,0.021-0.741,0.031-1.284,0.031
			c-0.063,0-0.094-0.063-0.094-0.188s0.031-0.188,0.094-0.188c0.647,0,1.085-0.151,1.315-0.454c0.229-0.302,0.344-0.871,0.344-1.706
			v-5.886c0-0.647-0.078-1.122-0.235-1.425c-0.157-0.302-0.402-0.454-0.736-0.454c-0.355,0-0.856,0.146-1.503,0.438h-0.063
			c-0.063,0-0.11-0.052-0.141-0.157c-0.031-0.104-0.026-0.167,0.016-0.188l4.352-2.129c0.125-0.042,0.198-0.063,0.219-0.063
			c0.229,0,0.449,0.224,0.657,0.673c0.208,0.449,0.334,1.06,0.376,1.832c1.44-1.711,3.026-2.567,4.759-2.567
			c1.127,0,2.02,0.36,2.677,1.08c0.657,0.72,0.986,1.665,0.986,2.834v6.012c0,0.835,0.12,1.404,0.36,1.706
			c0.24,0.303,0.683,0.454,1.331,0.454C87.504,108.124,87.535,108.187,87.535,108.312z" />
              <path d="M127.329,108.312c0,0.125-0.042,0.188-0.125,0.188c-0.543,0-0.971-0.01-1.284-0.031l-1.879-0.031l-1.816,0.031
			c-0.334,0.021-0.773,0.031-1.315,0.031c-0.063,0-0.094-0.063-0.094-0.188s0.031-0.188,0.094-0.188c0.647,0,1.09-0.151,1.331-0.454
			c0.24-0.302,0.36-0.871,0.36-1.706v-4.509c0-1.127-0.193-1.962-0.579-2.505c-0.387-0.543-0.976-0.814-1.769-0.814
			c-0.543,0-1.075,0.115-1.597,0.344c-0.522,0.23-0.971,0.543-1.346,0.939v0.188v6.356c0,0.835,0.12,1.404,0.36,1.706
			c0.24,0.303,0.683,0.454,1.331,0.454c0.083,0,0.125,0.063,0.125,0.188s-0.042,0.188-0.125,0.188c-0.543,0-0.971-0.01-1.284-0.031
			l-1.879-0.031l-1.816,0.031c-0.334,0.021-0.772,0.031-1.315,0.031c-0.063,0-0.094-0.063-0.094-0.188s0.031-0.188,0.094-0.188
			c0.647,0,1.091-0.151,1.331-0.454c0.24-0.302,0.36-0.871,0.36-1.706v-4.509c0-1.127-0.193-1.962-0.579-2.505
			c-0.386-0.543-0.976-0.814-1.769-0.814c-0.501,0-0.997,0.099-1.487,0.297c-0.491,0.199-0.924,0.475-1.299,0.83v6.7
			c0,0.835,0.12,1.404,0.36,1.706c0.24,0.303,0.683,0.454,1.331,0.454c0.063,0,0.094,0.063,0.094,0.188s-0.031,0.188-0.094,0.188
			c-0.543,0-0.971-0.01-1.284-0.031l-1.879-0.031l-1.847,0.031c-0.313,0.021-0.741,0.031-1.284,0.031
			c-0.063,0-0.094-0.063-0.094-0.188s0.031-0.188,0.094-0.188c0.647,0,1.085-0.151,1.315-0.454c0.229-0.302,0.344-0.871,0.344-1.706
			v-5.886c0-0.647-0.078-1.122-0.235-1.425c-0.157-0.302-0.402-0.454-0.736-0.454c-0.355,0-0.856,0.146-1.503,0.438h-0.063
			c-0.063,0-0.11-0.052-0.141-0.157c-0.031-0.104-0.026-0.167,0.016-0.188l4.352-2.129c0.125-0.042,0.198-0.063,0.219-0.063
			c0.229,0,0.449,0.224,0.657,0.673c0.208,0.449,0.334,1.049,0.376,1.8c1.419-1.691,2.943-2.536,4.571-2.536
			c0.981,0,1.764,0.24,2.348,0.72c0.584,0.48,0.939,1.169,1.064,2.066c1.44-1.857,3.037-2.787,4.791-2.787
			c1.127,0,1.988,0.324,2.583,0.971c0.595,0.647,0.892,1.628,0.892,2.943v6.012c0,0.835,0.12,1.404,0.36,1.706
			c0.24,0.303,0.683,0.454,1.331,0.454C127.287,108.124,127.329,108.187,127.329,108.312z" />
              <path d="M132.712,108.312c0-0.125,0.031-0.188,0.094-0.188c0.647,0,1.085-0.151,1.315-0.454c0.229-0.302,0.344-0.871,0.344-1.706
			v-5.605c0-0.709-0.073-1.221-0.219-1.534c-0.146-0.313-0.386-0.47-0.72-0.47c-0.271,0-0.657,0.115-1.158,0.345h-0.031
			c-0.084,0-0.146-0.057-0.188-0.172c-0.042-0.114-0.031-0.182,0.031-0.203l4.728-2.192l0.094-0.031
			c0.104,0,0.208,0.052,0.313,0.156c0.104,0.105,0.157,0.199,0.157,0.282v0.689c-0.042,0.835-0.063,1.858-0.063,3.068v5.667
			c0,0.835,0.12,1.404,0.36,1.706c0.24,0.303,0.683,0.454,1.331,0.454c0.063,0,0.094,0.063,0.094,0.188s-0.031,0.188-0.094,0.188
			c-0.543,0-0.971-0.01-1.284-0.031l-1.879-0.031l-1.847,0.031c-0.313,0.021-0.741,0.031-1.284,0.031
			C132.743,108.5,132.712,108.437,132.712,108.312z M134.402,92.501c-0.334-0.334-0.501-0.803-0.501-1.409
			c0-0.543,0.172-0.976,0.517-1.299c0.344-0.323,0.809-0.485,1.393-0.485c0.584,0,1.033,0.157,1.346,0.47
			c0.313,0.313,0.47,0.751,0.47,1.315c0,0.606-0.156,1.075-0.47,1.409c-0.313,0.334-0.762,0.501-1.346,0.501
			C135.206,93.002,134.736,92.835,134.402,92.501z" />
              <path d="M144.637,108.312c0-0.125,0.021-0.188,0.063-0.188c0.647,0,1.09-0.151,1.331-0.454c0.24-0.302,0.36-0.871,0.36-1.706
			l0.031-15.906c0-0.73-0.068-1.252-0.204-1.566c-0.136-0.313-0.371-0.47-0.704-0.47c-0.272,0-0.668,0.115-1.19,0.344h-0.031
			c-0.063,0-0.12-0.057-0.172-0.172c-0.052-0.114-0.058-0.182-0.016-0.203l4.603-2.16l0.125-0.031c0.104,0,0.219,0.047,0.345,0.141
			c0.125,0.094,0.188,0.183,0.188,0.266v19.757c0,0.835,0.114,1.404,0.344,1.706c0.229,0.303,0.668,0.454,1.315,0.454
			c0.083,0,0.125,0.063,0.125,0.188s-0.042,0.188-0.125,0.188c-0.522,0-0.939-0.01-1.252-0.031l-1.91-0.031l-1.879,0.031
			c-0.313,0.021-0.741,0.031-1.284,0.031C144.658,108.5,144.637,108.437,144.637,108.312z" />
              <path d="M156.691,108.312c0-0.125,0.021-0.188,0.063-0.188c0.647,0,1.09-0.151,1.331-0.454c0.24-0.302,0.36-0.871,0.36-1.706
			l0.031-15.906c0-0.73-0.068-1.252-0.204-1.566c-0.136-0.313-0.371-0.47-0.704-0.47c-0.272,0-0.668,0.115-1.19,0.344h-0.031
			c-0.063,0-0.12-0.057-0.172-0.172c-0.052-0.114-0.058-0.182-0.016-0.203l4.603-2.16l0.125-0.031c0.104,0,0.219,0.047,0.345,0.141
			c0.125,0.094,0.188,0.183,0.188,0.266v19.757c0,0.835,0.114,1.404,0.344,1.706c0.229,0.303,0.668,0.454,1.315,0.454
			c0.083,0,0.125,0.063,0.125,0.188s-0.042,0.188-0.125,0.188c-0.522,0-0.939-0.01-1.252-0.031l-1.91-0.031l-1.879,0.031
			c-0.313,0.021-0.741,0.031-1.284,0.031C156.712,108.5,156.691,108.437,156.691,108.312z" />
              <path d="M180.549,108.312c0-0.125,0.021-0.188,0.063-0.188c0.647,0,1.09-0.151,1.331-0.454c0.24-0.302,0.36-0.871,0.36-1.706
			l0.031-15.906c0-0.73-0.068-1.252-0.204-1.566c-0.136-0.313-0.371-0.47-0.704-0.47c-0.272,0-0.668,0.115-1.19,0.344h-0.031
			c-0.063,0-0.12-0.057-0.172-0.172c-0.052-0.114-0.058-0.182-0.016-0.203l4.603-2.16l0.125-0.031c0.104,0,0.219,0.047,0.345,0.141
			c0.125,0.094,0.188,0.183,0.188,0.266v19.757c0,0.835,0.114,1.404,0.344,1.706c0.229,0.303,0.668,0.454,1.315,0.454
			c0.083,0,0.125,0.063,0.125,0.188s-0.042,0.188-0.125,0.188c-0.522,0-0.939-0.01-1.252-0.031l-1.91-0.031l-1.879,0.031
			c-0.313,0.021-0.741,0.031-1.284,0.031C180.57,108.5,180.549,108.437,180.549,108.312z" />
              <path d="M204.429,106.809c0.083,0,0.141,0.047,0.172,0.141c0.031,0.094,0.005,0.162-0.078,0.203l-3.225,1.534
			c-0.084,0.042-0.188,0.063-0.313,0.063c-0.334,0-0.653-0.178-0.955-0.532c-0.303-0.355-0.517-0.835-0.642-1.44l-1.346,0.939
			c-0.48,0.355-0.929,0.611-1.346,0.767c-0.418,0.156-0.888,0.235-1.409,0.235c-0.898,0-1.576-0.199-2.035-0.595
			c-0.459-0.396-0.689-0.95-0.689-1.659c0-0.564,0.135-1.018,0.407-1.362c0.271-0.345,0.611-0.615,1.018-0.814
			c0.407-0.198,0.975-0.423,1.706-0.673l0.783-0.282l2.818-1.033v-1.785c0-1.336-0.11-2.306-0.329-2.912
			c-0.219-0.605-0.59-0.908-1.112-0.908c-1.107,0-1.733,0.856-1.879,2.567c-0.063,0.626-0.246,1.075-0.548,1.346
			c-0.303,0.272-0.725,0.407-1.268,0.407c-0.522,0-0.903-0.11-1.143-0.329c-0.24-0.219-0.36-0.506-0.36-0.861
			c0-0.626,0.365-1.226,1.096-1.8c0.73-0.574,1.613-1.038,2.646-1.393c1.033-0.355,1.946-0.532,2.74-0.532
			c0.918,0,1.664,0.287,2.239,0.861c0.574,0.574,0.861,1.446,0.861,2.614v5.542c0,0.606,0.11,1.075,0.329,1.409
			c0.219,0.334,0.527,0.501,0.924,0.501c0.292,0,0.595-0.073,0.908-0.219H204.429z M199.325,106.246
			c-0.021-0.083-0.031-0.219-0.031-0.407v-3.006l-1.346,0.626c-0.063,0.021-0.271,0.099-0.626,0.235
			c-0.355,0.136-0.663,0.36-0.924,0.673c-0.261,0.313-0.391,0.679-0.391,1.096c0,0.501,0.162,0.892,0.485,1.174
			c0.323,0.282,0.694,0.423,1.112,0.423c0.334,0,0.678-0.114,1.033-0.345L199.325,106.246z" />
              <path d="M224.257,108.312c0,0.125-0.031,0.188-0.094,0.188c-0.543,0-0.971-0.01-1.284-0.031l-1.879-0.031l-2.411,0.031
			c-0.355,0.021-0.835,0.031-1.44,0.031c-0.063,0-0.094-0.063-0.094-0.188s0.031-0.188,0.094-0.188c0.751,0,1.127-0.219,1.127-0.657
			c0-0.229-0.084-0.438-0.25-0.626l-3.569-4.571l-0.595,0.532v3.162c0,0.835,0.12,1.404,0.36,1.706
			c0.24,0.303,0.683,0.454,1.331,0.454c0.083,0,0.125,0.063,0.125,0.188s-0.042,0.188-0.125,0.188c-0.543,0-0.981-0.01-1.315-0.031
			l-1.816-0.031l-1.879,0.031c-0.313,0.021-0.741,0.031-1.284,0.031c-0.042,0-0.063-0.063-0.063-0.188s0.021-0.188,0.063-0.188
			c0.647,0,1.09-0.151,1.331-0.454c0.24-0.302,0.36-0.871,0.36-1.706V90.058c0-0.73-0.068-1.252-0.204-1.566
			c-0.136-0.313-0.371-0.47-0.705-0.47c-0.293,0-0.689,0.115-1.19,0.344h-0.031c-0.063,0-0.12-0.057-0.172-0.172
			c-0.052-0.114-0.057-0.182-0.016-0.203l4.571-2.16l0.156-0.031c0.104,0,0.214,0.047,0.329,0.141
			c0.114,0.094,0.172,0.183,0.172,0.266v15.874l2.912-2.818c0.543-0.501,0.814-1.012,0.814-1.534c0-0.271-0.105-0.496-0.313-0.673
			c-0.209-0.177-0.501-0.266-0.877-0.266c-0.042,0-0.063-0.063-0.063-0.188s0.02-0.188,0.063-0.188c0.605,0,1.075,0.011,1.409,0.031
			l2.474,0.031l2.286-0.031c0.292-0.02,0.699-0.031,1.221-0.031c0.063,0,0.094,0.063,0.094,0.188s-0.031,0.188-0.094,0.188
			c-0.898,0-1.926,0.224-3.084,0.673c-1.158,0.449-2.197,1.101-3.115,1.957l-1.127,1.033l4.29,5.511
			c0.605,0.751,1.184,1.299,1.738,1.644c0.553,0.344,1.112,0.517,1.675,0.517C224.226,108.124,224.257,108.187,224.257,108.312z" />
              <path d="M239.255,106.434c0.042,0,0.089,0.042,0.141,0.125c0.052,0.084,0.057,0.146,0.016,0.188
			c-0.773,0.751-1.555,1.295-2.348,1.628c-0.794,0.334-1.659,0.501-2.599,0.501c-1.315,0-2.453-0.277-3.413-0.83
			c-0.96-0.553-1.691-1.294-2.192-2.223c-0.501-0.929-0.751-1.936-0.751-3.022c0-1.21,0.302-2.327,0.908-3.35
			c0.605-1.022,1.445-1.832,2.521-2.426c1.075-0.595,2.29-0.892,3.648-0.892c1.294,0,2.296,0.293,3.006,0.877
			c0.709,0.585,1.064,1.493,1.064,2.724c0,0.647-0.115,0.971-0.344,0.971l-7.608,0.031c-0.021,0.146-0.031,0.387-0.031,0.72
			c0,1.837,0.423,3.277,1.268,4.321c0.845,1.044,2.061,1.565,3.648,1.565c0.563,0,1.043-0.063,1.44-0.188
			c0.396-0.125,0.918-0.355,1.565-0.689L239.255,106.434z M232.273,97.651c-0.459,0.595-0.762,1.414-0.908,2.458l4.54-0.063
			c-0.021-1.064-0.178-1.879-0.47-2.442c-0.293-0.563-0.773-0.845-1.44-0.845C233.306,96.759,232.732,97.056,232.273,97.651z" />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default SVGLogo;
