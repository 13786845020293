import * as React from "react";
import { useEffect } from "react";
// import { useEffect } from "react";
import S from "./svg-lake.module.sass";
import classNames from "classnames";
// import gsap from "gsap";

type Props = {
  setShowLake: (value: boolean) => void;
}

const SVGLake = ({ setShowLake }: Props) => {

  useEffect(() => {
    // gsap.to(".lake", {
    //   opacity: 0,
    //   duration: 2,
    //   delay: 4,
    //   onStart: () => {
    //     // console.log('start to fade out lake')
    //   },
    //   onComplete: () => {
    //     // console.log('remove lake');
    //     setShowLake(false);
    //   }
    // });
  }, []);


  const lineClass = (line: string, animation: string) => {
    return classNames(line, animation);
  };

  return (
    <svg viewBox="0 0 349.005 209.545" className={`lake ${S.lake}`}>
      <path className={lineClass(S.line1, S.animation0)} d="M28.53,63.14c5.75-3.41,5.52-6.84,13.43-10.25c9.12-3.92,10.83,0.05,19.95-3.88
	c7.72-3.32,7.69-6.67,13.03-10.01" />
      <path className={lineClass(S.line1, S.animation1)} d="M25.37,75.49h0.01c2.29-0.03,4.8-0.3,8.59-1.93c9.12-3.93,7.41-7.9,16.54-11.82
	c9.12-3.92,10.83,0.04,19.95-3.88S77.88,49.97,87,46.05c9.12-3.93,10.83,0.04,19.95-3.88c6.64-2.86,7.55-5.74,11.06-8.59l0.01-0.01" />
      <path className={lineClass(S.line1, S.animation2)} d="M22.62,86.25c9.08-3.87,10.79,0.07,19.9-3.85c9.12-3.92,7.42-7.89,16.54-11.81c9.12-3.93,10.83,0.04,19.95-3.88
	c9.12-3.93,7.42-7.89,16.54-11.82c9.12-3.92,10.83,0.05,19.96-3.88c9.12-3.92,7.41-7.89,16.54-11.82
	c9.12-3.92,10.83,0.05,19.95-3.88c1.65-0.71,2.94-1.42,4.01-2.13" />
      <path className={lineClass(S.line1, S.animation3)} d="M17.74,105.35c5.69-3.41,5.49-6.83,13.37-10.22c9.13-3.92,10.83,0.04,19.96-3.88
	c9.12-3.92,7.42-7.89,16.54-11.82c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.54-11.81
	c9.13-3.93,10.83,0.04,19.96-3.88c9.12-3.93,7.42-7.89,16.54-11.82c9.12-3.92,10.83,0.05,19.96-3.88c5.41-2.33,7.01-4.67,9.33-7.02" />
      <path className={lineClass(S.line1, S.animation4)} d="M14.57,117.73c2.29-0.04,4.79-0.32,8.55-1.94c9.13-3.92,7.42-7.89,16.54-11.81c9.13-3.93,10.84,0.04,19.96-3.88
	c9.12-3.93,7.42-7.9,16.54-11.82s10.83,0.04,19.95-3.88c9.13-3.92,7.42-7.89,16.54-11.82c9.13-3.92,10.83,0.05,19.96-3.87
	c9.12-3.93,7.42-7.9,16.54-11.82c9.13-3.93,10.83,0.04,19.96-3.88c8.92-3.84,7.48-7.71,15.95-11.55h0.01" />
      <path className={lineClass(S.line1, S.animation5)} d="M11.82,128.48c9.03-3.85,10.76,0.07,19.85-3.84c9.13-3.92,7.42-7.89,16.55-11.82
	c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.54-11.81c9.13-3.93,10.83,0.04,19.96-3.88
	c9.12-3.93,7.41-7.89,16.53-11.82c9.13-3.92,10.84,0.05,19.96-3.88c9.12-3.92,7.42-7.89,16.54-11.82
	c9.13-3.92,10.83,0.05,19.96-3.88c9.12-3.92,7.42-7.89,16.54-11.81c6.55-2.82,9.27-1.57,13.66-2.09h0.01" />
      <path className={lineClass(S.line1, S.animation6)}
            d="M275.85,16.7c3.21-0.05,6.01,0.34,11.31-1.94c3.8-1.64,5.72-3.27,7.32-4.91" />
      <path className={lineClass(S.line1, S.animation7)} d="M7.74,147.05c4.85-3.22,5.05-6.47,12.53-9.68c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.9,16.55-11.82
	c9.12-3.92,10.83,0.04,19.95-3.88c9.13-3.92,7.42-7.89,16.54-11.82c9.13-3.92,10.83,0.05,19.96-3.88
	c9.12-3.92,7.41-7.89,16.54-11.81c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.89,16.54-11.82
	c9.13-3.92,10.83,0.05,19.96-3.88c9.12-3.92,7.42-7.89,16.54-11.82c9.13-3.92,10.83,0.05,19.96-3.88c2.75-1.18,4.52-2.37,5.89-3.56" />
      <path className={lineClass(S.line1, S.animation8)} d="M245.04,41.46c4.7-0.69,7.37,0.77,14.17-2.15c9.12-3.93,7.42-7.9,16.54-11.82c9.13-3.93,10.84,0.04,19.97-3.89
	c9.12-3.92,7.42-7.89,16.55-11.82c7.4-3.18,9.92-1.17,15.48-2.39" />
      <path className={lineClass(S.line1, S.animation9)} d="M12.87,157.76c8.46-3.83,7.02-7.71,15.95-11.55c9.12-3.92,10.83,0.05,19.95-3.88
	c9.13-3.92,7.42-7.89,16.55-11.81c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.9,16.55-11.82c9.12-3.92,10.82,0.04,19.95-3.88
	c9.12-3.92,7.41-7.89,16.54-11.82c9.12-3.92,10.83,0.05,19.95-3.87c9.13-3.93,7.42-7.9,16.55-11.82c9.12-3.93,10.83,0.04,19.95-3.88
	c9.12-3.93,7.42-7.9,16.54-11.82c9.13-3.92,10.83,0.04,19.96-3.88c9.13-3.93,7.42-7.89,16.54-11.82c9.13-3.92,10.83,0.05,19.96-3.88
	c9.13-3.92,7.42-7.89,16.55-11.82c9.12-3.92,10.83,0.05,19.96-3.88c9.12-3.93,7.42-7.89,16.55-11.82c6.48-2.79,9.22-1.6,13.54-2.08" />
      <path className={lineClass(S.line1, S.animation10)} d="M20.89,166.85c9.05-3.92,7.38-7.88,16.48-11.79c9.12-3.92,10.83,0.04,19.95-3.88
	c9.13-3.92,7.42-7.89,16.55-11.82c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.55-11.81
	c9.12-3.93,10.83,0.04,19.95-3.88c9.12-3.93,7.41-7.9,16.54-11.82c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.55-11.82
	c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.54-11.81c9.13-3.93,10.84,0.04,19.96-3.88c9.13-3.93,7.42-7.9,16.55-11.82
	c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.9,16.55-11.82c9.12-3.93,10.83,0.04,19.96-3.88c9.13-3.93,7.42-7.9,16.55-11.82
	c2.32-1,4.17-1.49,5.77-1.73" />
      <path className={lineClass(S.line1, S.animation11)} d="M28.9,175.92c0.16-0.06,0.32-0.13,0.48-0.2c9.12-3.92,7.42-7.89,16.54-11.81c9.13-3.93,10.83,0.04,19.96-3.88
	c9.12-3.93,7.41-7.9,16.54-11.82c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.89,16.55-11.82c9.12-3.92,10.83,0.05,19.95-3.88
	c9.12-3.92,7.42-7.89,16.54-11.81c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.9,16.55-11.82c9.12-3.92,10.83,0.04,19.95-3.88
	c9.13-3.92,7.42-7.89,16.54-11.82c9.13-3.92,10.84,0.05,19.96-3.88c9.13-3.92,7.42-7.89,16.55-11.82
	c9.12-3.92,10.83,0.05,19.95-3.87c9.13-3.93,7.42-7.9,16.55-11.82c9.12-3.93,10.83,0.04,19.96-3.89c8.36-3.59,7.63-7.22,14.45-10.81
	" />
      <path className={lineClass(S.line1, S.animation12)} d="M36.91,184.99c0.33-0.13,0.67-0.27,1.02-0.42c9.12-3.92,7.42-7.89,16.54-11.82c9.13-3.92,10.83,0.05,19.96-3.88
	c9.12-3.92,7.42-7.89,16.54-11.81c9.12-3.93,10.83,0.04,19.96-3.88c9.12-3.93,7.41-7.9,16.54-11.82c9.12-3.92,10.83,0.04,19.95-3.88
	c9.12-3.92,7.42-7.89,16.54-11.82c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.55-11.81
	c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.9,16.55-11.82c9.12-3.92,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.89,16.55-11.82
	c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.55-11.82c9.13-3.92,10.83,0.05,19.96-3.88c3.4-1.46,5.29-2.93,6.8-4.39" />
      <path className={lineClass(S.line1, S.animation13)} d="M44.9,194.04c0.5-0.17,1.03-0.38,1.58-0.62c9.13-3.93,7.42-7.9,16.54-11.82c9.13-3.92,10.83,0.04,19.96-3.88
	c9.12-3.92,7.42-7.89,16.54-11.82c9.13-3.92,10.83,0.05,19.96-3.88c9.12-3.92,7.41-7.89,16.54-11.81
	c9.12-3.93,10.83,0.04,19.95-3.88c9.12-3.93,7.42-7.9,16.54-11.82s10.83,0.05,19.96-3.88c9.12-3.92,7.41-7.89,16.54-11.82
	c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.55-11.81c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.9,16.55-11.82
	c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.9,16.55-11.82c8.8-3.79,10.7-0.23,19.01-3.49" />
      <path className={lineClass(S.line1, S.animation14)} d="M55.46,202.06c8.65-3.85,7.14-7.75,16.11-11.61c9.13-3.93,10.84,0.04,19.96-3.88
	c9.12-3.93,7.42-7.9,16.54-11.82c9.13-3.93,10.83,0.04,19.96-3.88c9.12-3.93,7.42-7.89,16.54-11.82c9.12-3.92,10.83,0.05,19.95-3.88
	c9.13-3.92,7.42-7.89,16.54-11.81c9.13-3.93,10.83,0.04,19.96-3.88c9.12-3.93,7.42-7.9,16.54-11.82c9.12-3.92,10.83,0.04,19.96-3.88
	c9.12-3.92,7.41-7.89,16.54-11.82c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.55-11.82
	c9.12-3.92,10.83,0.05,19.95-3.87c9.13-3.93,7.42-7.9,16.55-11.82c5.17-2.23,7.96-1.92,11.07-1.95" />
      <path className={lineClass(S.line1, S.animation15)} d="M338.7,88.28c-0.93,0.28-1.95,0.64-3.09,1.13c-9.13,3.93-7.42,7.9-16.55,11.82
	c-9.12,3.93-10.83-0.04-19.95,3.88c-9.13,3.93-7.42,7.89-16.54,11.82c-9.13,3.92-10.84-0.04-19.96,3.88
	c-9.13,3.92-7.42,7.89-16.54,11.82c-9.13,3.92-10.83-0.05-19.96,3.88c-9.12,3.92-7.42,7.89-16.54,11.81
	c-9.13,3.93-10.83-0.04-19.96,3.88c-9.12,3.93-7.41,7.9-16.54,11.82c-9.12,3.92-10.83-0.05-19.95,3.88
	c-9.12,3.92-7.42,7.89-16.54,11.82c-9.13,3.92-10.83-0.05-19.96,3.88c-9.12,3.92-7.41,7.89-16.54,11.81
	c-0.82,0.35-1.58,0.64-2.29,0.88h-0.01" />
      <path className={lineClass(S.line1, S.animation16)}
            d="M91.57,197.35h-0.01c-3.26,0.06-6.06-0.37-11.43,1.94c-0.01,0-0.01,0.01-0.02,0.01" />
      <path className={lineClass(S.line1, S.animation17)} d="M146.24,188.05c7.89-3.75,6.7-7.55,15.43-11.31c9.13-3.92,10.83,0.05,19.96-3.87
	c9.12-3.93,7.41-7.9,16.53-11.82c9.13-3.92,10.83,0.05,19.96-3.88c9.12-3.92,7.42-7.89,16.54-11.82c9.13-3.92,10.83,0.05,19.96-3.88
	c9.12-3.92,7.42-7.89,16.54-11.81c9.12-3.93,10.83,0.04,19.96-3.88c9.12-3.93,7.42-7.9,16.54-11.82c9.13-3.93,10.83,0.04,19.96-3.88
	c7.09-3.06,7.64-6.13,11.82-9.2" />
      <path className={lineClass(S.line1, S.animation18)} d="M185.43,183.26h0.01c1.36-0.28,2.9-0.76,4.74-1.55c9.12-3.92,7.41-7.89,16.54-11.81
	c9.12-3.93,10.83,0.04,19.95-3.88c9.13-3.93,7.42-7.9,16.54-11.82c9.13-3.92,10.83,0.04,19.96-3.88c9.12-3.92,7.42-7.89,16.54-11.82
	c9.13-3.92,10.83,0.05,19.96-3.88c9.12-3.92,7.42-7.89,16.54-11.82c9.13-3.92,10.83,0.05,19.96-3.88c1.75-0.75,3.1-1.5,4.2-2.26" />
      <path className={lineClass(S.line1, S.animation19)} d="M205.9,185.8c2.33-2.35,3.92-4.71,9.37-7.06c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.54-11.81
	c9.13-3.93,10.84,0.04,19.96-3.88c9.12-3.93,7.42-7.9,16.54-11.82c9.13-3.92,10.83,0.04,19.96-3.88c9.12-3.93,7.42-7.89,16.54-11.82
	c7.78-3.34,10.16-0.95,16.34-2.6" />
      <path className={lineClass(S.line1, S.animation20)} d="M223.05,187.93c0.25-0.11,0.51-0.23,0.77-0.34c9.12-3.92,10.83,0.04,19.95-3.88
	c9.13-3.92,7.42-7.89,16.55-11.82c9.12-3.92,10.83,0.05,19.95-3.88c9.13-3.92,7.42-7.89,16.54-11.81
	c9.13-3.93,10.83,0.04,19.96-3.88c9.13-3.93,7.42-7.9,16.55-11.82c2.63-1.13,4.64-1.61,6.39-1.81h0.01" />
      <path className={lineClass(S.line1, S.animation21)} d="M254,191.77c7.27-3.67,6.35-7.37,14.87-11.03c9.12-3.92,10.83,0.04,19.95-3.88c8.23-3.53,7.65-7.11,14.13-10.64
	l0.01-0.01" />
      <path className={lineClass(S.line2, S.animation22)} d="M341.29,132.55c0,2.1-0.56,4.2-1.57,6.14h-0.01c-2.24,4.33-6.69,7.93-12.07,9.27
            c-3.46,0.42-6.74,2.3-10.05,5.26l-14.63,12.99l-0.01,0.01l-24.43,21.68c-4.1,3.68-8.88,5.75-13.61,5.23L254,191.77l-30.95-3.84
            l-17.15-2.13l-20.46-2.54h-0.01l-4.09-0.51c-1.51-0.19-3.03-0.15-4.53,0.1l-30.57,5.2l-48.45,8.24h-0.01l-6.21,1.06h-0.01
            l-11.45,1.95l-18.05,3.07c-2.23,0.38-4.48,0.26-6.6-0.31c-2.91-0.77-5.58-2.38-7.62-4.69l-2.94-3.33l-7.99-9.05l-16.02-18.14
            l-8.02-9.09l-1.37-1.55c-2.29-2.6-3.59-5.84-3.76-9.16c-0.11-1.89,0.15-3.81,0.78-5.66l3.3-12.91l2.75-10.75l3.17-12.38l4.88-19.1
            l2.75-10.76h0.01l3.15-12.35l2.59-10.11c1.73-5.07,6.06-8.84,11.37-9.94L74.94,39l43.07-5.42l0.01-0.01l24.48-3.08
            c3.88-0.13,7.73,0.7,11.5,2.12l2.01,0.57l13.88,3.96l15.17,4.32h0.01l22.79,6.49h0.01l9.76,2.78c3.85,1.21,7.57,1.35,10.97,0.71
            c3.87-0.74,7.32-2.49,10.03-4.83l6.41-5.15l30.01-24.11c0.27-0.22,0.53-0.44,0.8-0.65c3.3-2.64,6.85-4.9,9.96-5.8l8.67-1.05
            l23.05-2.78c3.73-0.45,7.3,0.45,10.22,2.32c3.23,2.06,5.66,5.29,6.61,9.16c0.17,0.68,0.29,1.38,0.36,2.1l0.42,7.1l0.68,11.58
            l0.9,15.27l0.8,13.63l0.61,10.39l0.57,9.66l0.74,12.6l0.93,15.78l0.73,12.39l0.14,2.4C341.27,131.82,341.29,132.18,341.29,132.55z" />
    </svg>
  );
};

export default SVGLake;

